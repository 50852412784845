import { PipeTransform } from "@angular/core";
import { Observable, combineLatest } from "rxjs";
import { FilterSettingsStore } from "../stores/filter-settings-store";
import { FilterEvent } from "../models/filters/filter-event.interface";
import { IFilter } from "../models/filters/filter.interface";
import { map } from "rxjs/operators";

export abstract class FilterPipe implements PipeTransform {
    constructor(protected store: FilterSettingsStore) {}

    transform(observables$: Observable<any[]>): Observable<any[]> {
        return combineLatest([
            observables$,
            this.store.getCurrentSettings$(),
        ]).pipe(
            map(([observables, settings]) => {
                if (!observables || !observables.length) {
                    return [];
                }
                if (!settings || !settings.length) {
                    return observables;
                }
                const res = settings
                    .map((event) => this.getFilter(event))
                    .reduce(
                        (filtered, filter) =>
                            filter ? [...filter.meets(filtered)] : filtered,
                        observables,
                    );
                return res;
            }),
        );
    }

    protected abstract getFilter(event: FilterEvent): IFilter;
}
